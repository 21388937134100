import { takeLatest, put, select } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { showAlertAction } from 'reducers/popUps/alertPopUpReducer';
import updateStateArray from 'reducers/utils/updateStateArray';
import handleUnauthorizedResponse from 'handlers/handleUnauthorizedResponse';
import * as actionTypes from '../../../../actions/actionType';
import ApiClient from '../../../../Services/ApiClient';
import { getUserNameFromProfile } from '../selectors';
// ***************************************************************
// UPDATE
// ****************************************************************

export const fetchInHouseSolutionSet = (params) => ({
  type: actionTypes.FETCH_INHOUSE_SOLUTION_SET,
  payload: {
    params
  }
});

const fetchInHouseSolutionSetSuccess = (data) => ({
  type: actionTypes.FETCH_INHOUSE_SOLUTION_SET_SUCCESS,
  payload: {
    data
  }
});

const fetchInHouseSolutionSetError = (error) => ({
  type: actionTypes.FETCH_INHOUSE_SOLUTION_SET_FAILURE,
  payload: {
    error
  }
});

export const fetchOrderedSolutionSet = (params) => ({
  type: actionTypes.FETCH_ORDERED_SOLUTION_SET,
  payload: {
    params
  }
});

const fetchOrderedSolutionSetSuccess = (data) => ({
  type: actionTypes.FETCH_ORDERED_SOLUTION_SET_SUCCESS,
  payload: {
    data
  }
});

const fetchOrderedSolutionSetError = (error) => ({
  type: actionTypes.FETCH_ORDERED_SOLUTION_SET_FAILURE,
  payload: {
    error
  }
});

export const fetchOrderedThirdPartySolutionSet = (params) => ({
  type: actionTypes.FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET,
  payload: {
    params
  }
});

const fetchOrderedThirdPartySolutionSetSuccess = (data) => ({
  type: actionTypes.FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET_SUCCESS,
  payload: {
    data
  }
});

const fetchOrderedThirdPartySolutionSetError = (error) => ({
  type: actionTypes.FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET_FAILURE,
  payload: {
    error
  }
});

export const updateStatus = (data) => ({
  type: actionTypes.UPDATE_STATUS,
  payload: {
    data
  }
});

const updateStatusSuccess = (data, applicationId) => ({
  type: actionTypes.UPDATE_STATUS_SUCCESS,
  payload: {
    data,
    applicationId
  }
});

const updateStatusFailure = (error) => ({
  type: actionTypes.UPDATE_STATUS_FAILURE,
  payload: {
    error
  }
});

export const getLicenseHistory = (data) => ({
  type: actionTypes.GET_LICENSE_HISTORY,
  payload: {
    data
  }
});

const getLicenseHistorySuccess = (data) => ({
  type: actionTypes.GET_LICENSE_HISTORY_SUCCESS,
  payload: {
    data
  }
});

const getLicenseHistoryFailure = (error) => ({
  type: actionTypes.GET_LICENSE_HISTORY_FAILURE,
  payload: {
    error
  }
});

export const updatePublishStatus = (data) => ({
  type: actionTypes.UPDATE_PUBLISH_STATUS,
  payload: {
    data
  }
});

const updatePublishStatusSuccess = (data, productId) => ({
  type: actionTypes.UPDATE_PUBLISH_STATUS_SUCCESS,
  payload: {
    data,
    productId
  }
});

const updatePublishStatusFailure = (error) => ({
  type: actionTypes.UPDATE_PUBLISH_STATUS_FAILURE,
  payload: {
    error
  }
});

export const deleteDraft = (data) => ({
  type: actionTypes.DELETE_DRAFT,
  payload: {
    data
  }
});

const deleteDraftSuccess = (data) => ({
  type: actionTypes.DELETE_DRAFT_SUCCESS,
  payload: {
    data
  }
});

const deleteDraftFailure = (error) => ({
  type: actionTypes.DELETE_DRAFT_FAILURE,
  payload: {
    error
  }
});

const inHousesolutionIndexReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INHOUSE_SOLUTION_SET:
      return {
        ...state,
        loading: true,
        error: null,
        data: []
      };
    case actionTypes.UPDATE_STATUS:
    case actionTypes.UPDATE_PUBLISH_STATUS:
    case actionTypes.DELETE_DRAFT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_INHOUSE_SOLUTION_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };
    case actionTypes.UPDATE_STATUS_SUCCESS: {
      const index = state.data.findIndex((product) => product.applicationId === action.payload.applicationId);
      return {
        ...state,
        data: updateStateArray(state.data, index, {
          ...state.data[index],
          selectedLicenseStatusOption: {
            ...action.payload.data.newLicenseStatus,
          }
        }),
        loading: false,
        error: null,
      };
    }
    case actionTypes.UPDATE_PUBLISH_STATUS_SUCCESS: {
      const index = state.data.findIndex((product) => product.applicationId === action.payload.productId);
      return {
        ...state,
        data: updateStateArray(state.data, index, {
          ...state.data[index],
          ...action.payload.data,
        }),
        loading: false,
        error: null,
      };
    }
    case actionTypes.DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => item.applicationGuid !== action.payload.data),
        loading: false,
        error: null,
      };
    case actionTypes.FETCH_INHOUSE_SOLUTION_SET_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error
      };
    case actionTypes.UPDATE_STATUS_FAILURE:
    case actionTypes.UPDATE_PUBLISH_STATUS_FAILURE:
    case actionTypes.DELETE_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};
const orderedsolutionIndexReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERED_SOLUTION_SET:
      return {
        ...state,
        loading: true,
        error: null,
        data: []
      };
    case actionTypes.UPDATE_STATUS:
    case actionTypes.UPDATE_PUBLISH_STATUS:
    case actionTypes.DELETE_DRAFT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_ORDERED_SOLUTION_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };
    case actionTypes.UPDATE_STATUS_SUCCESS: {
      const index = state.data.findIndex((product) => product.applicationId === action.payload.applicationId);
      return {
        ...state,
        data: updateStateArray(state.data, index, {
          ...state.data[index],
          selectedLicenseStatusOption: {
            ...action.payload.data.newLicenseStatus,
          }
        }),
        loading: false,
        error: null,
      };
    }
    case actionTypes.UPDATE_PUBLISH_STATUS_SUCCESS: {
      const index = state.data.findIndex((product) => product.applicationId === action.payload.productId);
      return {
        ...state,
        data: updateStateArray(state.data, index, {
          ...state.data[index],
          ...action.payload.data,
        }),
        loading: false,
        error: null,
      };
    }
    case actionTypes.DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => item.applicationGuid !== action.payload.data),
        loading: false,
        error: null,
      };
    case actionTypes.FETCH_ORDERED_SOLUTION_SET_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error
      };
    case actionTypes.UPDATE_STATUS_FAILURE:
    case actionTypes.UPDATE_PUBLISH_STATUS_FAILURE:
    case actionTypes.DELETE_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};
const orderedThirdPartysolutionIndexReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET:
      return {
        ...state,
        loading: true,
        error: null,
        data: []
      };
    case actionTypes.UPDATE_STATUS:
    case actionTypes.UPDATE_PUBLISH_STATUS:
    case actionTypes.DELETE_DRAFT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: null
      };
    case actionTypes.UPDATE_STATUS_SUCCESS: {
      const index = state.data.findIndex((product) => product.applicationId === action.payload.applicationId);
      return {
        ...state,
        data: updateStateArray(state.data, index, {
          ...state.data[index],
          selectedLicenseStatusOption: {
            ...action.payload.data.newLicenseStatus,
          }
        }),
        loading: false,
        error: null,
      };
    }
    case actionTypes.UPDATE_PUBLISH_STATUS_SUCCESS: {
      const index = state.data.findIndex((product) => product.applicationId === action.payload.productId);
      return {
        ...state,
        data: updateStateArray(state.data, index, {
          ...state.data[index],
          ...action.payload.data,
        }),
        loading: false,
        error: null,
      };
    }
    case actionTypes.DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        data: state.data.filter(item => item.applicationGuid !== action.payload.data),
        loading: false,
        error: null,
      };
    case actionTypes.FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload.error
      };
    case actionTypes.UPDATE_STATUS_FAILURE:
    case actionTypes.UPDATE_PUBLISH_STATUS_FAILURE:
    case actionTypes.DELETE_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

const getLicenseHistoryReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_LICENSE_HISTORY:
      return {
        loading: true,
        error: null
      };

    case actionTypes.GET_LICENSE_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: null
      };

    case actionTypes.GET_LICENSE_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    default:
      return state;
  }
};

const permissionsSelector = (state) => state.profile.accessingUserProfileReducer;

export function* fetchInHouseSolutionSetWatcher(action) {
  try {
    const permissions = yield select(permissionsSelector);
    let url = '';
    let params = '';
    if (
      permissions
      && (
        permissions.canUserManageInternalProducts
        || permissions.canUserManageDeveloperProducts
        || permissions.canUserViewPurchasedSolutionsForOrg
      )
    ) {
      url = 'Profile/GetCreatedSolutionsForOrg';
      params = action.payload;
      const SolutionSet = yield ApiClient.httpClient.get(url, { ...params });
      yield put(fetchInHouseSolutionSetSuccess(SolutionSet.data.agencySolutions));
    } else if (permissions && permissions.canUserViewSolutions) {
      url = 'profile/getmysolutionsforuser';
      const username = yield select(getUserNameFromProfile);
      params = { username, solutionType: 'InHouse' };
      const SolutionSet = yield ApiClient.httpClient.get(url, { params: { ...params } });
      yield put(fetchInHouseSolutionSetSuccess(SolutionSet.data));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(fetchInHouseSolutionSetError(error));
  }
}
export function* fetchOrderedSolutionSetWatcher(action) {
  try {
    const permissions = yield select(permissionsSelector);
    let url = '';
    let params = '';
    if (
      permissions
      && (
        permissions.canUserManageInternalProducts
        || permissions.canUserManageDeveloperProducts
        || permissions.canUserViewPurchasedSolutionsForOrg
      )
    ) {
      url = 'Profile/GetOrderedTPSolutionsForAgencyOrg';
      params = action.payload;
      const SolutionSet = yield ApiClient.httpClient.get(url, { ...params });
      yield put(fetchOrderedSolutionSetSuccess(SolutionSet.data.agencySolutions));
    } else if (permissions && permissions.canUserViewSolutions) {
      url = 'profile/getmysolutionsforuser';
      const username = yield select(getUserNameFromProfile);
      params = { username, solutionType: 'TPSolutions' };
      const SolutionSet = yield ApiClient.httpClient.get(url, { params: { ...params } });
      yield put(fetchOrderedSolutionSetSuccess(SolutionSet.data));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(fetchOrderedSolutionSetError(error));
  }
}
export function* fetchOrderedThirdPartySolutionSetWatcher(action) {
  try {
    const permissions = yield select(permissionsSelector);
    let url = '';
    let params = '';
    if (
      permissions
      && (
        permissions.canUserManageInternalProducts
        || permissions.canUserManageDeveloperProducts
        || permissions.canUserViewPurchasedSolutionsForOrg
      )
    ) {
      url = 'Profile/GetOrderedThirdPartySolutionsForAgencyOrg';
      params = action.payload;
      const SolutionSet = yield ApiClient.httpClient.get(url, { ...params });
      yield put(fetchOrderedThirdPartySolutionSetSuccess(SolutionSet.data.agencySolutions));
    } else if (permissions && permissions.canUserViewSolutions) {
      url = 'profile/getmysolutionsforuser';
      const username = yield select(getUserNameFromProfile);
      params = { username, solutionType: 'ThirdParty' };
      const SolutionSet = yield ApiClient.httpClient.get(url, { params: { ...params } });
      yield put(fetchOrderedThirdPartySolutionSetSuccess(SolutionSet.data));
    }
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(fetchOrderedThirdPartySolutionSetError(error));
  }
}

export function* fetchUpdateStatusWatcher(action) {
  try {
    const { data } = action.payload;
    const statusUpdated = yield ApiClient.httpClient.put('profile/changelicensestatus', { ...data });
    yield put(updateStatusSuccess(statusUpdated.data, data.applicationId));
    yield put(showAlertAction('The plug-in status has been successfully changed.'));
    data.onSuccess();
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(updateStatusFailure(error));
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export function* fetchGetLicenseHistoryWatcher(action) {
  try {
    const { data } = action.payload;
    const licenseHistory = yield ApiClient.httpClient.get('profile/getlicensehistory', { params: { licId: data.licId } });
    yield put(getLicenseHistorySuccess(licenseHistory.data));
  } catch (error) {
    yield put(getLicenseHistoryFailure(error));
  }
}

export function* fetchUpdatePublishStatusWatcher(action) {
  try {
    const { data } = action.payload;
    const publishStatus = yield ApiClient.httpClient.put('profile/changeproductpublish', { ...data });
    yield put(updatePublishStatusSuccess(publishStatus.data, data.productId));
    yield put(showAlertAction(publishStatus.data.isPublished ? 'The product has been published successfully.' : 'The product has been unpublished successfully.'));
    yield ApiClient.httpClient.post(`profile/SendNewSolutionNotification?productId=${data.productId}&publish=${publishStatus.data.isPublished}`);
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(updatePublishStatusFailure(error));
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export function* deleteDraftWatcher(action) {
  try {
    const data = action.payload.data.applicationGuid;
    yield ApiClient.httpClient.delete(`application/deletedraft/${data}`);
    yield put(deleteDraftSuccess(data));
    action.payload.data.popUpHide();
    yield put(showAlertAction('The draft has been deleted successfully'));
  } catch (error) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorizedResponse();
    }
    yield put(deleteDraftFailure(error));
    action.payload.data.popUpHide();
    yield put(showAlertAction(error && error.response && error.response.data));
  }
}

export function* mySolutionMiddleware() {
  yield takeLatest(actionTypes.FETCH_INHOUSE_SOLUTION_SET, fetchInHouseSolutionSetWatcher);
  yield takeLatest(actionTypes.FETCH_ORDERED_SOLUTION_SET, fetchOrderedSolutionSetWatcher);
  yield takeLatest(actionTypes.FETCH_ORDERED_THIRD_PARTY_SOLUTION_SET, fetchOrderedThirdPartySolutionSetWatcher);
  yield takeLatest(actionTypes.UPDATE_STATUS, fetchUpdateStatusWatcher);
  yield takeLatest(actionTypes.GET_LICENSE_HISTORY, fetchGetLicenseHistoryWatcher);
  yield takeLatest(actionTypes.UPDATE_PUBLISH_STATUS, fetchUpdatePublishStatusWatcher);
  yield takeLatest(actionTypes.DELETE_DRAFT, deleteDraftWatcher);
}

const mySolutionReducer = combineReducers({
  inhouse: inHousesolutionIndexReducer,
  ordered: orderedsolutionIndexReducer,
  thirdParty: orderedThirdPartysolutionIndexReducer,
  licenseHistory: getLicenseHistoryReducer,
});

export default mySolutionReducer;
