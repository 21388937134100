/* eslint-disable no-useless-constructor */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from '@atlas-design-system/react';
import '@atlas-design-system/core/dist/styles/partials/dialog.css';

class SpcNotification extends PureComponent {
  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    window.open(this.props.NetworkURL, '_blank', 'noopener,noreferrer');
    popUpHide();
  }

  hideModal = (e) => {
    const { popUpHide } = this.props;
    e.preventDefault();
    popUpHide();
  }

  render() {
    return (
      <Dialog
        appearance="success"
        id="sbDialog"
        hidden={!this.props.showModal}
        buttons={
          (
            <>
              <Button
                className="action-button"
                appearance="primary"
                onClick={this.hideModal}
              >
                Done
              </Button>
              <Button
                className="cancel-button space-left"
                onClick={this.handleClick}
              >
                Go to Smartpoint Cloud
              </Button>
            </>
          )
        }
      >
        {`${this.props.productName} is now available on your Smartpoint Cloud account.`}
      </Dialog>
    );
  }
}

SpcNotification.propTypes = {
  NetworkURL: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  productName: PropTypes.string,
  popUpHide: PropTypes.func
};

export default SpcNotification;
