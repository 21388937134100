import { takeLatest } from 'redux-saga/effects';
import {
  /* license information */
  LICENSE_INFORMATION,
  getlicenseInformationSaga,
  /* assignments */
  ADD_ASSIGNMENTS,
  REMOVE_ASSIGNMENTS,
  addAssignmentsSaga,
  removeAssignmentsSaga,
  /* members */
  GET_ASSIGNED_MEMBERS,
  GET_UNASSIGNED_MEMBERS,
  ADD_MEMBER_ASSIGNMENTS,
  REMOVE_MEMBER_ASSIGNMENTS,
  getAssignedMembersSaga,
  getUnassignedMembersSaga,
  addMemberAssignmentsSaga,
  removeMemberAssignmentsSaga,
  /* pccs */
  GET_ASSIGNED_PCCS,
  GET_UNASSIGNED_PCCS,
  ADD_PCC_ASSIGNMENTS,
  REMOVE_PCC_ASSIGNMENTS,
  getAssignedPccsSaga,
  getUnassignedPccsSaga,
  addPccAssignmentsSaga,
  removePccAssignmentsSaga,
} from '.';

function* licenseAssignmentsMiddleware() {
  /* license information */
  yield takeLatest(LICENSE_INFORMATION.BASE, getlicenseInformationSaga);
  /* assignments */
  yield takeLatest(ADD_ASSIGNMENTS, addAssignmentsSaga);
  yield takeLatest(REMOVE_ASSIGNMENTS, removeAssignmentsSaga);
  /* members */
  yield takeLatest(GET_ASSIGNED_MEMBERS.BASE, getAssignedMembersSaga);
  yield takeLatest(GET_UNASSIGNED_MEMBERS.BASE, getUnassignedMembersSaga);
  yield takeLatest(ADD_MEMBER_ASSIGNMENTS.BASE, addMemberAssignmentsSaga);
  yield takeLatest(REMOVE_MEMBER_ASSIGNMENTS.BASE, removeMemberAssignmentsSaga);
  /* pccs */
  yield takeLatest(GET_ASSIGNED_PCCS.BASE, getAssignedPccsSaga);
  yield takeLatest(GET_UNASSIGNED_PCCS.BASE, getUnassignedPccsSaga);
  yield takeLatest(ADD_PCC_ASSIGNMENTS.BASE, addPccAssignmentsSaga);
  yield takeLatest(REMOVE_PCC_ASSIGNMENTS.BASE, removePccAssignmentsSaga);
}

export default licenseAssignmentsMiddleware;
